import React, { useState, useMemo } from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme, message, Avatar, Dropdown, Space, ConfigProvider } from 'antd';
import { Outlet, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import Settings from '../../dependencies/custom/settings';
import useAdd from '../../hooks/add';
import ValuesStore from '../../store/values-store';
import { Offline, Online } from "react-detect-offline";
import { Helmet, HelmetProvider } from 'react-helmet-async';
const { Header, Content, Sider, Footer } = Layout;


const App = () => {
    const valuesStore = ValuesStore();
    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(false);
    const [selectedMenuItem, setSelectedMenuItem] = useState('1');
    const [tokenValid, setTokenValid] = useState(false);
    const [pages, setPages] = useState([]);
    const [avatar, setAvatar] = useState(undefined);
    const [schoolDetails, setSchoolDetails] = useState();
    const qh = utils.getHString();
    const siderWidth = 208;
    const [breadcrumb, setBreadcrumb] = useState([]);
    const [themeConf, setThemeConf] = useState({});
    const [layoutType, setLayoutType] = useState('layout1');
    const add = useAdd('tables_metadata', 'table_name');
    const [selectedPage, setSelectedPage] = useState('');
    const [htmlHead, setHtmlHead] = useState({});
    const {
        token: { colorBgContainer },
    } = theme.useToken();


    function changePage(page, key) {
        setSelectedMenuItem(key);
        navigate(page);
        setSelectedPage(page);
    }

    // function changePassword() {
    //     add.setTblName('admin');
    //     add.setShowModal(true);
    //     add.setSaveCompleted(false);
    // }

    // async function addOnOk() {
    //     const data = { ...add.record, token: utils.getCookie('token') };
    //     const res = await utils.requestWithReauth('post', `${Settings.backend}/change_admin_password`, null, data);
    //     message.success(res.msg);
    //     if (res.status === 'Ok') {
    //         add.setShowModal(false);
    //         utils.logout(navigate);
    //     }
    // }


    useMemo(() => {
        const institution = valuesStore.getArrayObjectsValue('settings', 'prop', 'INSTITUTION_DETAILS')?.value;
        if (institution) {
            setSchoolDetails(JSON.parse(institution));
        }

        const themeConfig = valuesStore.getArrayObjectsValue('settings', 'prop', 'themeConfig')?.value;
        if (themeConfig) {
            const a = JSON.parse(themeConfig);
            setThemeConf(a);
        }

        const layout = valuesStore.getArrayObjectsValue('settings', 'prop', 'layoutType')?.value;
        if (layout) {
            setLayoutType(layout);
        }
        const hhead = valuesStore.getArrayObjectsValue('settings', 'prop', 'htmlMeta')?.value;
        if (hhead) {
            const a = JSON.parse(hhead);
            setHtmlHead(a);
        }


        if (qh['page']) {
            setSelectedMenuItem(qh['page']);
        }
        getAssignedPages();
        getAssignedPemissions();
        utils.verifyToken(navigate, setTokenValid);
        // console.log(utils.getUrlFileName(),);
        currentBreadCrumb();

    }, [window.location.hash, add.saveCompleted, valuesStore['settings']]);

    function currentBreadCrumb() {
        const currPage = valuesStore.getValue('permitted_routes')?.filter(v => {
            return v.path == utils.getUrlFileName()
        });

        setBreadcrumb([{ title: 'Finance' }, { title: currPage[0]?.description }])
    }

    async function getAssignedPages() {
        const token = utils.getCookie('token');
        let res = await utils.requestWithReauth('post', `${Settings.backend}/get_assigned_pages`, null, { token });
        valuesStore.setValue('permitted_routes', res);
        if (Array.isArray(res)) {
            const pages = res?.map(r => {
                return {
                    key: r?.id,
                    icon: <i className={`${r?.icon}`} />,
                    label: <label onClick={e => changePage(r?.path, r?.id.toString())}>{r?.description}</label>,
                }
            });
            setPages(pages);
        } else {
            message.error(res.msg);
        }
    }

    async function getAssignedPemissions() {
        const token = utils.getCookie('token');
        let res = await utils.requestWithReauth('post', `${Settings.backend}/get_assigned_permissions`, null, { token });
        valuesStore.setValue('permissions', res);
    }


    function menu(height = '100%') {
        return <Menu
            className='h-scrolling-item border-end fw-boldx'
            mode="inline"
            selectedKeys={[selectedMenuItem]}
            style={{
                height: height,
                borderRight: 0,
            }}
            items={pages}
        />
    }

    function headerItems() {
        return <div className='d-flex justify-content-between'>
            <div className='d-flex'>
                <div className="demo-logo" >{schoolDetails?.logo && <img src={`${Settings.root}/${schoolDetails?.logo}`} width={schoolDetails?.logoWidth} height={schoolDetails?.logoHeight} />}</div>
                <label style={{ color: 'rgb(140, 11, 59)', fontSize: '15px' }} className='fw-boldx text-uppercase text-white'>{valuesStore.getArrayObjectsValue('settings', 'prop', 'APP_NAME')?.value}</label>
                <label className='fw-boldx text-white'> {valuesStore.getArrayObjectsValue('settings', 'prop', 'APP_VERSION')?.value}</label>
            </div>
            <div className='d-flex'>
                <Dropdown menu={{
                    items: [
                        {
                            key: '1',
                            label: (
                                <a onClick={e => navigate('./change_password')}>
                                    <i className='fas fa-sign-out-alt' /> Change Password
                                </a>
                            ),
                        },
                        {
                            key: '2',
                            label: (
                                <a onClick={e => utils.logout(navigate)}>
                                    <i className='fas fa-sign-out-alt' /> Sign out
                                </a>
                            ),
                        }
                    ]
                }} arrow>
                    <a onClick={(e) => e.preventDefault()}>
                        <Space>
                            <Avatar size='large' icon={<UserOutlined />} src={avatar} />
                        </Space>
                    </a>
                </Dropdown>
            </div>
        </div>
    }

    function layout2() {
        return <Layout>
            <Header className={`sticky-top`}>
                {headerItems()}
            </Header>
            <Content
                style={{
                    padding: '0 48px',
                }}
            >
                <Breadcrumb
                    style={{
                        margin: '16px 0',
                    }}
                    items={breadcrumb}
                />
                <Layout>
                    <Sider
                        width={siderWidth}
                    >
                        {menu()}
                    </Sider>
                    <Content
                        style={{
                            margin: 0,
                            minHeight: 280,
                        }}
                        className='w-100'
                    >
                        <div className=''>
                            {tokenValid && <Outlet />}
                        </div>
                    </Content>
                </Layout>
            </Content>
            <Footer style={{ textAlign: 'center' }}>{schoolDetails?.alias || ''} © 2022 - {new Date().getFullYear()}</Footer>
        </Layout>
    }


    function layout1() {
        return <Layout>
            <Header className={`sticky-top`}>
                {headerItems()}
            </Header>
            <Layout>
                <Sider
                    className='h-scrolling-wrapper border-endx'
                    width={siderWidth}
                    style={{
                        overflow: 'auto',
                        height: '300vh',
                        position: 'fixed',
                        left: 0,
                        // top: '8vh',
                        // bottom: 0,
                    }}
                >
                    {menu('120%')}
                </Sider>
                <Layout
                    style={{
                        padding: '0 24px 24px',
                        marginLeft: siderWidth,
                    }}
                >
                    <Breadcrumb
                        style={{
                            margin: '16px 0',
                        }}
                        items={breadcrumb}
                    />

                    <Content
                        style={{
                            margin: 0,
                            minHeight: 280,
                        }}
                        className='w-100'
                    >
                        <div className=''>
                            {tokenValid && <Outlet />}
                        </div>
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>{schoolDetails?.alias || ''} © 2022 - {new Date().getFullYear()}</Footer>
                </Layout>
            </Layout>
        </Layout>
    }

    function layout3() {
        return <Layout className='' style={{ overflowX: 'hidden' }}>
            <Sider
                width={siderWidth}
                breakpoint="lg"
                onBreakpoint={(broken) => {

                }}
                onCollapse={(collapsed, type) => {
                    // console.log(collapsed, type);
                }}
                collapsedWidth="0"
                style={{
                    overflow: 'auto',
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                    // top: 0,
                    // bottom: 0,
                }}
                className='h-scrolling-wrapper'
                trigger={null}
                collapsible
                collapsed={collapsed}
            >
                <div className='d-flex justify-content-center'>
                    <div className="p-2 mx-auto" >{schoolDetails?.logo && <img src={`${Settings.root}/${schoolDetails?.logo}`} width={schoolDetails?.logoWidth} height={schoolDetails?.logoHeight} />}</div>
                </div>
                {menu()}
            </Sider>
            <Layout
                style={{
                    marginLeft: siderWidth,
                }}
                className="w-100"
            >
                <Header className=''>
                    <div className='container-fluid border-bottomx'>
                        <div className='d-flex justify-content-between'>
                            <div>
                                <label style={{fontSize: '15px' }} className='fw-bold text-uppercase'>{valuesStore.getArrayObjectsValue('settings', 'prop', 'APP_NAME')?.value}</label>
                                <label className='fw-bold'> {valuesStore.getArrayObjectsValue('settings', 'prop', 'APP_VERSION')?.value}</label>
                            </div>
                            <div className='d-flex'>
                                <Dropdown menu={{
                                    items: [
                                        {
                                            key: '1',
                                            label: (
                                                <a onClick={e => navigate('./change_password')}>
                                                    <i className='fas fa-sign-out-alt' /> Change Password
                                                </a>
                                            ),
                                        },
                                        {
                                            key: '2',
                                            label: (
                                                <a onClick={e => utils.logout(navigate)}>
                                                    <i className='fas fa-sign-out-alt' /> Sign out
                                                </a>
                                            ),
                                        }
                                    ]
                                }} arrow>
                                    <a onClick={(e) => e.preventDefault()}>
                                        <Space>
                                            <Avatar size='large' icon={<UserOutlined />} src={avatar} />
                                        </Space>
                                    </a>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </Header>

                <Content
                    className="w-100"
                    style={{
                        // margin: '24px 16px',
                        padding: 24,
                        minHeight: 280,
                    }}
                >
                    <div className='bg-whitex p-3x'>
                        {/* <Online>
                        <p>You are online.</p>
                    </Online> */}
                        {/* <Offline>
                        <Alert
                            message="Internet Connection Offline"
                            description="OOPs! You are offline. Please check your internet connection."
                            type="error"
                            closable
                            className='mb-3'
                        />
                    </Offline> */}
                        {tokenValid && <Outlet />}
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>{schoolDetails?.alias || ''} © 2022 - {new Date().getFullYear()}</Footer>
            </Layout>
        </Layout>
    }

    function layoutSwitcher(layoutName = 'layout1') {
        switch (layoutName) {
            case 'layout1':
                return layout1();
            case 'layout2':
                return layout2();
            case 'layout3':
                return layout3();
            default:
                return layout1();
        }
    }



    return (
        <ConfigProvider
            theme={{
                algorithm: themeConf?.algorithm?.map(name => theme[name]),
                components: {
                    ...themeConf?.components
                },
            }}
        >
            <HelmetProvider>
                <Helmet>
                    <meta name="description" content={htmlHead?.description} />
                    <link rel="icon" href={htmlHead?.favIcon} />
                    <link rel="apple-touch-icon" href={htmlHead?.favIcon} />
                    <title>{`${htmlHead?.title} | ${selectedPage}`}</title>
                </Helmet>
            </HelmetProvider>
            {layoutSwitcher(layoutType)}
        </ConfigProvider>
    );
};
export default App;

